@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.contact-box {
  display: -ms-grid;
  display: grid;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(125, 59, 177, 0.7);
          box-shadow: 0 10px 20px rgba(125, 59, 177, 0.7);
}

.contact-box .contact-map {
  width: 100%;
  height: 350px;
  -webkit-filter: grayscale(100%) invert(92%) contrast(90%);
          filter: grayscale(100%) invert(92%) contrast(90%);
  -ms-grid-row: 2;
  grid-row: 2 / 2;
}

.contact-box .contact-map iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0 0 10px 10px;
}

.contact-box .contact-form {
  color: #fff;
  background-color: #0a121d;
  padding: 40px;
  border-radius: 10px 10px 0 0;
}

.contact-box .contact-form .contact-title {
  font-size: 26px;
  margin-bottom: 30px;
}

.contact-box .contact-form .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.contact-box .contact-form .form-row:not(:last-child) {
  margin-bottom: 15px;
}

.contact-box .contact-form .form-row label {
  margin-bottom: 4px;
}

.contact-box .contact-form .form-row .form-input {
  color: #fff;
  background: transparent;
  padding: 6px 0;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #fff;
}

.contact-box .contact-form .form-row .form-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-box .contact-form .form-row .form-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-box .contact-form .form-row .form-input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-box .contact-form .form-row .form-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-box .contact-form .form-row .form-input.red {
  border-color: #f73a3a;
}

.contact-box .contact-form .form-row .form-textarea {
  min-height: 100px;
  resize: none;
}

.contact-box .contact-form .form-row .custom-check {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contact-box .contact-form .form-row .custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.contact-box .contact-form .form-row .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  width: 19px;
  height: 19px;
  background-color: #fff;
  border-radius: 4px;
}

.contact-box .contact-form .form-row .custom-check input:checked ~ .checkmark {
  background-color: #fa2da5;
}

.contact-box .contact-form .form-row .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.contact-box .contact-form .form-row .custom-check input:checked ~ .checkmark:after {
  display: block;
}

.contact-box .contact-form .form-row .custom-check .checkmark:after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.contact-box .contact-form .form-row .submit-btn {
  display: inline-block;
  max-width: 150px;
  color: #fff;
  background: linear-gradient(167deg, #fa2da5 0%, #7d3bb1 100%);
  border-radius: 6px;
  padding: 10px;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 10px rgba(125, 59, 177, 0.3);
          box-shadow: 0 5px 10px rgba(125, 59, 177, 0.3);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.contact-box .contact-form .form-row .submit-btn:hover {
  -webkit-box-shadow: 0 5px 15px rgba(125, 59, 177, 0.8);
          box-shadow: 0 5px 15px rgba(125, 59, 177, 0.8);
}

.contact-box .contact-form .form-row .submit-btn[disabled] {
  opacity: .6;
  cursor: not-allowed;
}

.contact-box .contact-form .form-row .success-msg {
  color: #09c309;
}

.contact-box .contact-form .form-row .error-msg {
  color: #f73a3a;
}

.contact-box .contact-form .multi-row {
  display: -ms-grid;
  display: grid;
  grid-gap: 15px;
}

.contact-box .contact-form .multi-row .form-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media all and (min-width: 375px) {
  .contact-box .contact-form .contact-title {
    font-size: 40px;
  }
}

@media all and (min-width: 600px) {
  .contact-box .contact-form .multi-row {
    -ms-grid-columns: calc(65% - 15px) 35%;
        grid-template-columns: calc(65% - 15px) 35%;
  }
}

@media all and (min-width: 768px) {
  .contact-box {
    -ms-grid-columns: 40% 60%;
        grid-template-columns: 40% 60%;
  }
  .contact-box .contact-map {
    height: 100%;
    grid-row: initial;
  }
  .contact-box .contact-map iframe {
    border-radius: 10px 0 0 10px;
  }
  .contact-box .contact-form {
    border-radius: 0 10px 10px 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.nav-flex {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100px;
  z-index: 9999;
}

.nav-flex .nav-logo {
  width: 100%;
  max-width: 300px;
  margin-right: 25px;
}

.nav-flex .nav-menu {
  position: fixed;
  top: 100px;
  left: 100%;
  width: 100%;
  height: 100vh;
  background: #0a121d;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.nav-flex .nav-menu.active {
  left: 0;
}

.nav-flex .nav-menu .nav-links {
  padding: 30px;
}

.nav-flex .nav-menu .nav-links .link {
  display: inline-block;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.nav-flex .nav-menu .nav-links .link.active {
  background: linear-gradient(167deg, #fa2da5 0%, #7d3bb1 100%);
  -webkit-box-shadow: 0 6px 10px -3px rgba(250, 45, 165, 0.8);
          box-shadow: 0 6px 10px -3px rgba(250, 45, 165, 0.8);
}

.nav-flex .nav-toggle {
  font-size: 30px;
  cursor: pointer;
}

@media all and (min-width: 1024px) {
  .nav-menu {
    position: initial !important;
    width: auto !important;
    height: auto !important;
    background: transparent !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  .nav-menu .nav-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .nav-menu .nav-links .link {
    position: relative;
    font-size: 16px !important;
    border-radius: 5px;
    padding: 10px 18px !important;
  }
  .nav-menu .nav-links .link::after {
    position: absolute;
    content: '';
    bottom: 6px;
    left: 19px;
    width: 0;
    height: 2px;
    background-color: #fa2da5;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  .nav-menu .nav-links .link:hover::after {
    width: calc(100% - 36px);
  }
  .nav-menu .nav-links .link:hover.active::after {
    width: 0;
  }
  .nav-toggle {
    display: none;
  }
}

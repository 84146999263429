@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.search-input {
  display: block;
  width: 100%;
  max-width: 250px;
  background: #f1f1f1;
  font-size: 14px;
  margin: 9px auto 30px auto;
  border-radius: 5px;
  padding: 8px 12px;
  outline: 0;
  border: 0;
  border-bottom: 3px solid #fa2da5;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media all and (min-width: 768px) {
  .search-input {
    float: right;
  }
}

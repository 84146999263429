@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.box-cards-section {
  padding: 10px 0 30px 0;
}

.box-cards {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.box-cards .box-card {
  position: relative;
  color: #fff;
  background: linear-gradient(167deg, #fa2da5 0%, #7d3bb1 100%);
  border-radius: 5px;
  padding: 30px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  overflow: hidden;
}

.box-cards .box-card .line-set {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box-cards .box-card .line-set::before, .box-cards .box-card .line-set::after {
  position: absolute;
  content: '';
  background: #fff;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.box-cards .box-card .line-set.first::before, .box-cards .box-card .line-set.first::after {
  top: 0;
  left: 0;
}

.box-cards .box-card .line-set.second::before, .box-cards .box-card .line-set.second::after {
  bottom: 0;
  right: 0;
}

.box-cards .box-card .line-set.first::before {
  width: 0;
  height: 2px;
}

.box-cards .box-card .line-set.first::after {
  width: 2px;
  height: 0;
}

.box-cards .box-card .line-set.second::before {
  width: 2px;
  height: 0;
}

.box-cards .box-card .line-set.second::after {
  width: 0;
  height: 2px;
}

.box-cards .box-card:hover {
  -webkit-box-shadow: 0 4px 10px rgba(250, 45, 165, 0.4);
          box-shadow: 0 4px 10px rgba(250, 45, 165, 0.4);
}

.box-cards .box-card:hover .line-set.first::before {
  width: 100%;
}

.box-cards .box-card:hover .line-set.first::after {
  height: 100%;
}

.box-cards .box-card:hover .line-set.second::before {
  height: 100%;
}

.box-cards .box-card:hover .line-set.second::after {
  width: 100%;
}

.box-cards .box-card .card-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.box-cards .box-card .card-head .card-title {
  font-size: 22px;
  word-break: break-word;
}

.box-cards .box-card .card-head .card-icon {
  font-size: 50px;
  margin-right: 15px;
}

@media all and (min-width: 575px) {
  .box-cards {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (min-width: 900px) {
  .box-cards {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.grid-icons {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 75px;
}

.grid-icons .icon-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.grid-icons .icon-box .icon-img {
  width: 150px;
  height: 175px;
}

.grid-icons .icon-box .icon-img img {
  width: 100%;
  height: 100%;
}

.grid-icons .icon-box .icon-text {
  color: #fa2da5;
}

@media all and (min-width: 500px) {
  .grid-icons {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .grid-icons .icon-box:last-child {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
  }
}

@media all and (min-width: 650px) {
  .grid-icons {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
  .grid-icons .icon-box:last-child {
    grid-column: initial;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.copyright {
  text-align: center;
  margin-top: 40px;
}

.copyright .developer {
  color: #fa2da5;
  font-weight: 600;
}

.copyright .made-with {
  margin-top: 5px;
}

.copyright .made-with .fa-heart {
  color: #c71c1c;
}

.copyright .made-with .fa-react {
  color: #61dbfb;
}

.copyright .made-with .fa-node-js {
  color: #3c873a;
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.intro-text {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: calc(100vh - 100px);
  padding: 0 20px;
  z-index: 10;
}

.intro-text h1, .intro-text h2, .intro-text h3 {
  color: #fff;
  text-align: center;
}

.intro-text h1 {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 40px;
}

.intro-text h2 {
  font-size: 26px;
  line-height: 26px;
  text-decoration: underline;
}

.intro-text h3 {
  font-size: 20px;
  line-height: 28px;
}

.intro-text .link {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: 3px solid #fff;
  margin-top: 30px;
  padding: 12px 24px;
  border-radius: 6px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.intro-text .link:hover {
  color: #0a121d;
  background-color: #fff;
}

.intro-text .link i {
  margin-right: 10px;
}

@media all and (min-width: 480px) {
  .intro-text h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .intro-text h2 {
    font-size: 34px;
    line-height: 34px;
  }
  .intro-text h3 {
    font-size: 24px;
    line-height: 34px;
  }
}

@media all and (min-width: 768px) {
  .intro-text h1 {
    font-size: 60px;
    line-height: 60px;
  }
  .intro-text h2 {
    font-size: 46px;
    line-height: 46px;
  }
  .intro-text h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body, input, select, textarea {
  font-size: 16px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 500;
}

a {
  color: #fa2da5;
  text-decoration: none;
}

ul, li {
  list-style-type: none;
}

img {
  max-width: 100%;
}

header {
  position: relative;
  color: #fff;
  background-color: #0a121d;
  height: 100%;
  z-index: 99999;
}

header.bg--shape_down::after {
  top: 20px;
}

footer {
  color: #fff;
  background-color: #0a121d;
  height: 100%;
  margin-top: 125px;
}

/* CUSTOM CLASSES */
.policy-title {
  margin-top: 25px;
}

.circle-list {
  margin-left: 20px;
  list-style-type: circle;
}

.outter-page {
  max-width: 1240px;
  margin: 0 auto;
}

.inner-page {
  position: relative;
  padding: 0 20px;
  z-index: 10;
}

.section-padding {
  padding: 60px 0;
}

.position--initial {
  position: initial;
}

.text-white--color {
  color: #fff;
}

.dark--background {
  background-color: #0a121d;
}

.bg--shape_up,
.bg--shape_down,
.bg--shape_video {
  position: relative;
}

.bg--shape_up::before {
  position: absolute;
  content: '';
  bottom: calc(100% - 5px);
  left: 0;
  width: 100%;
  height: 85px;
  background: url("./assets/waves_up.svg");
  background-size: cover;
}

.bg--shape_down::after {
  position: absolute;
  content: '';
  top: calc(100% - 40px);
  left: 0;
  width: 100%;
  height: 140px;
  background: url("./assets/waves_down.svg");
  background-size: cover;
}

.bg--shape_video::before {
  position: absolute;
  content: '';
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 150px;
  background: url("./assets/waves_video.svg");
  background-size: cover;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  z-index: 100;
}

.inner-banner::before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: url("./assets/waves_video.svg");
  background-size: cover;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  z-index: 100;
}

.inner-banner::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.section-title {
  position: relative;
  display: inline-block;
  font-size: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.section-title::after {
  position: absolute;
  content: '';
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fa2da5;
}

.section-title--center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.not-found-message {
  font-size: 60px;
  font-weight: 600;
  text-align: center;
}

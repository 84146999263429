.inner-banner .page-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  height: 400px;
  color: #fff;
  font-size: 60px;
  line-height: 60px;
  z-index: 10;
}

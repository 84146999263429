@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.aboutme-section {
  padding: 60px 0 140px 0;
}

.aboutme-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 75px;
}

.aboutme-img-container {
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0 10px 20px rgba(125, 59, 177, 0.8));
          filter: drop-shadow(0 10px 20px rgba(125, 59, 177, 0.8));
}

.aboutme-img-container .aboutme-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-mask-image: url("../../assets/mask.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
}

.aboutme-img-container .aboutme-img img {
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
}

.aboutme-img-container .aboutme-img img:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.aboutme-description .section-title {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.aboutme-description .description {
  margin-bottom: 10px;
}

.aboutme-description .aboutme-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}

.aboutme-description .aboutme-btns .aboutme-resume {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  color: #fff;
  background: #7d3bb1;
  border-radius: 10px;
  margin-top: 20px;
  padding: 8px 8px 8px 20px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.aboutme-description .aboutme-btns .aboutme-resume:hover {
  background: #0a121d;
}

.aboutme-description .aboutme-btns .aboutme-resume:hover .icon {
  color: #0a121d;
}

.aboutme-description .aboutme-btns .aboutme-resume .icon {
  color: #7d3bb1;
  background: #fff;
  font-size: 16px;
  padding: 8px 10px;
  border-radius: 8px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.aboutme-description .aboutme-btns .aboutme-social-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}

.aboutme-description .aboutme-btns .aboutme-social-media .icon {
  color: #fff;
  background: #7d3bb1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.aboutme-description .aboutme-btns .aboutme-social-media .icon:hover {
  background: #0a121d;
}

@media all and (min-width: 500px) {
  .aboutme-description .aboutme-btns {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media all and (min-width: 768px) {
  .aboutme-section {
    padding: 50px 0 160px 0;
  }
  .aboutme-container {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .aboutme-container .aboutme-img-container {
    min-width: 300px;
  }
  .aboutme-container .aboutme-description .section-title {
    left: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@media all and (min-width: 1024px) {
  .aboutme-container .aboutme-img-container {
    min-width: 375px;
  }
}

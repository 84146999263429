@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.projects-section {
  padding-top: 125px;
}

.projects {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
}

.projects .project-item {
  background: #f1f1f1;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 5px rgba(10, 18, 29, 0.2);
          box-shadow: 0 2px 5px rgba(10, 18, 29, 0.2);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.projects .project-item:hover {
  -webkit-box-shadow: 0 10px 10px rgba(125, 59, 177, 0.3);
          box-shadow: 0 10px 10px rgba(125, 59, 177, 0.3);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.projects .project-item .project-details {
  padding: 15px 25px 25px;
}

.projects .project-item .project-details .title {
  font-size: 24px;
}

.projects .project-item .project-details .title a {
  color: #0a121d;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.projects .project-item .project-details .title a:hover {
  color: #7d3bb1;
}

.projects .project-item .project-details .description {
  margin-bottom: 10px;
}

.projects .project-item .project-details .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.projects .project-item .project-details .tags .tag {
  color: #fff;
  background: #0a121d;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 5px;
}

@media all and (min-width: 768px) {
  .project-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    gap: 15px;
  }
  .project-item .project-img {
    max-width: 350px;
    min-width: 350px;
  }
  .project-item .project-img img {
    width: 100%;
    height: 100%;
  }
  .project-item .project-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 15px !important;
  }
  .project-item .project-details .description {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}

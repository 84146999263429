@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
.footer-padding {
  padding: 30px 0;
}

.footer-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px;
}

.footer-flex h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.footer-flex .tech-names {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.footer-flex .tech-names .tech-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer-flex .tech-names .tech-row .tech-icon {
  font-size: 24px;
  width: 30px;
  text-align: center;
  margin-right: 7px;
}

.footer-flex .tech-names .tech-row .tech-icon .html5-icon {
  color: #f06529;
}

.footer-flex .tech-names .tech-row .tech-icon .css3-icon {
  color: #2965f1;
}

.footer-flex .tech-names .tech-row .tech-icon .sass-icon {
  color: #cd6799;
}

.footer-flex .tech-names .tech-row .tech-icon .js-icon {
  color: #f0db4f;
}

.footer-flex .tech-names .tech-row .tech-icon .react-icon {
  color: #61dbfb;
}

.footer-flex .tech-names .tech-row .tech-icon .node-icon {
  color: #3c873a;
}

.footer-flex .footer-links li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-flex .footer-links li .link {
  display: inline-block;
  color: #fff;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.footer-flex .footer-links li .link:hover {
  color: #fa2da5;
}

@media all and (min-width: 750px) {
  .footer-flex {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .footer-flex .footer-col {
    width: 100%;
    max-width: 33.3333333%;
  }
}

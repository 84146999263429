.hero-video {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.hero-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh + 100px);
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 5;
}
